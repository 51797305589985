import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

function TyC() {
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Términos y Condiciones",
        }}
      />
      <main className="container">
        <section className="mt-10 w-10/12 space-y-5">
          <h6 className="text-xl text-exoticberry">
            Última modificación Abril, 2016
          </h6>
          <p>
            Bienvenido a nuestro sitio (el «Sitio»). El Sitio tiene la intención
            de proporcionarle a Usted información con relación a nuestros vinos,
            campañas y eventos de patrocinio. También le permite interactuar con
            nosotros y otros y le otorga la oportunidad de contactarnos
            directamente. Aunque queremos que usted disfrute la experiencia de
            visitar nuestro Sitio, también queremos que usted entienda los
            términos que usted acepta cuando visita nuestro Sitio. Referencias a
            «nosotros» o «nuestro» o «Nuestra Marca» aquí dentro se refieren a
            E. & J. Gallo Winery y sus marcas, afiliadas, subsidiarias,
            proveedores de servicio y designados según nosotros consideremos
            apropiado.
          </p>
          <h6 className="text-xl text-exoticberry">
            Cumplimiento con los Términos
          </h6>
          <p>
            Al ingresar al Sitio, Usted está aceptando los Términos de Uso,
            nuestro Aviso de Privacidad (insert link), así como otros avisos
            legales, términos y políticas publicados en el Sitio de tiempo en
            tiempo (conjuntamente identificados como los «Términos»), todos los
            cuales se incluyen expresamente aquí mismo por medio de esta
            referencia.
          </p>
          <h6 className="text-xl text-exoticberry">Modificaciones </h6>
          <p>
            Podemos modificar o dar por terminado cualquiera de los Términos en
            cualquier momento. Si modificamos o damos por terminado cualquiera
            de los Términos, le notificaremos dicha modificación o terminación
            mediante la publicación de los Términos modificados en el Sitio, y
            dichos Términos modificados entrarán en vigor al momento de su
            publicación en el Sitio. Cada vez que utilice el Sitio, usted
            debería ir y revisar los Términos vigentes aplicables a sus
            operaciones y uso del Sitio. El uso continuo del Sitio posterior a
            la publicación de los Términos modificados significa que usted
            acepta dichos Términos modificados. Si usted no está de acuerdo con
            el Sitio, su contenido o cualquier de los Términos (incluyendo las
            modificaciones), usted acepta que su único y exclusivo recurso es
            suspender su uso del Sitio. Los Términos son el contrato completo
            entre usted y nuestra Marca con relación a su uso del Sitio.
          </p>
          <h6 className="text-xl text-exoticberry">Elegibilidad</h6>
          <p>
            No se le permite ver el Sitio a menos que usted sea mayor a 18 años
            de edad. Si usted tiene menos de 18 años, favor de no visitar el
            Sitio.
          </p>
          <h6 className="text-xl text-exoticberry">Términos </h6>
          <p>
            Los Términos, según sean modificados de tiempo en tiempo,
            permanecerán en pleno vigor y efectos mientras usted utilice las
            aplicaciones del Sitio.
          </p>
          <h6 className="text-xl text-exoticberry">Su Uso del Sitio</h6>
          <p>
            Los usuarios tienen una licencia intransferible, no exclusiva para
            ingresar al Sitio, para ver información incluida en el Sitio, e
            interactuar con el Sitio únicamente para su uso personal y no para
            fines comerciales. Usted acepta no utilizar el Sitio para fines
            ilícitos. Usted acepta no rentar, retransmitir, revelar, publicar,
            vender, ceder, alquilar, sublicenciar, comercializar o transferir el
            Sitio o cualquier parte del mismo o utilizarlo de cualquier forma no
            expresamente autorizada en estos Términos. Usted acepta no copiar,
            utilizar ingeniería inversa, traducir, modificar, o realizar
            trabajos derivados de cualquier parte del Sitio Está estrictamente
            prohibido adulterar el Sitio, mentir sobre la identidad o edad del
            Usuario, utilizar agentes de compra o realizar actividades
            fraudulentas.
          </p>
          <h6 className="text-xl text-exoticberry">Contenido</h6>
          <p>
            Este Sitio puede incluir Contenido proporcionado por nuestra Marca,
            incluyendo pero no limitado a texto, imágenes y logotipos
            («Contenido de la Marca»). El Contenido de la Marca se encuentra
            protegido por derechos de autor, marcas registradas, patentes,
            secretos industriales y otras leyes, y somos titulares y retenemos
            todos los derechos del Contenido de la Marca y sus elementos y
            funciones del Sitio. Por medio del presente le otorgamos una
            licencia no sublicenciable, limitada, revocable, para reproducir y
            exhibir el Contenido de la Marca (excluyendo cualquier código de
            software) únicamente para fines de uso personal con relación a
            navegar el Sitio. Este Sitio puede incluir Contenido de Usuarios y
            otros licenciantes. Salvo por lo establecido dentro de los Términos,
            usted no puede copiar, modificar, traducir, publicar, transmitir,
            distribuir, ejecutar, exhibir, utilizar para fines comerciales, o
            vender cualquier parte del Contenido que aparezca en o a través del
            Sitio.
          </p>
          <h6 className="text-xl text-exoticberry">
            Protección de Derechos de Propiedad Intelectual
          </h6>
          <p>
            Nosotros respetamos la propiedad intelectual de terceros, y
            requerimos que nuestros Usuarios hagan lo mismo. Usted no debe
            subir, insertar, publicar, enviar por correo, transmitir o de
            ninguna otra forma poner a disposición de otros cualquier material
            que viole cualquier derecho de autor, patentes, marcas comerciales,
            secretos industriales u otros derechos de propiedad de cualquier
            persona o entidad. Nos reservamos el derecho de cancelar la
            Membresía de cualquier persona que sospechemos sea un infractor. Si
            usted cree que su propiedad intelectual ha sido copiada y/o puesta a
            nuestra disposición de cualquier forma que se considere una
            violación a derechos de autor, por favor contáctenos en el número de
            teléfono (+1) 800-750-8828 o al email consumer contacto@localhost
            para solicitar la eliminación del contenido.{" "}
          </p>
          <h6 className="text-xl text-exoticberry">
            Ausencia de Responsabilidad
          </h6>
          <p>
            No somos responsables por y no otorgamos garantías, expresas o
            implícitas, con relación al contenido del Sitio, incluyendo pero no
            limitado con relación a la precisión y veracidad del Contenido de la
            Marca, el Contenido del Usuario u otro Contenido publicado en o a
            través del Sitio, o de cualquier otra forma. La inclusión de
            cualquier enlace a un sitio web en el Sitio no implica aprobación o
            respaldo del sitio web del enlace por nuestra Marca. Cuando usted
            ingresa a estos sitios de terceros, lo hace bajo su propio riesgo.
            No asumimos ninguna responsabilidad por publicidad de terceros o
            aplicaciones de terceros que son publicados en o a través de nuestro
            Sitio, ni tampoco asumimos ninguna responsabilidad por los bienes o
            servicios que se otorgan por nuestros anunciantes. No somos
            responsables de la conducta, ya sea en línea o fuera de línea, de
            cualquier Usuario del Sitio, incluyendo sin limitación, cualquier
            Contenido publicado por cualquier Usuario. No asumimos ninguna
            responsabilidad por cualquier error, omisión, interrupción,
            supresión, defecto, retraso en operación o transmisión, fallas en
            las líneas de comunicación, robo o destrucción o acceso no
            autorizado a, o alteración de, cualquier comunicación de un Usuario
            o Miembro. No somos responsables de ningún problema o falla técnica
            de cualquier teléfono, red o líneas, sistemas en línea de cómputo,
            servidores o proveedores, equipo de cómputo, software, falla de
            cualquier correo electrónico o reproductores debido a problemas
            técnicos o congestión del tráfico en el Internet o en cualquier
            parte del Sitio o una combinación de ambos, incluyendo cualquier
            lesión o daño a los Usuarios o a la computadora de cualquier persona
            relacionado con o como resultado en su participación o bajando
            material con relación al Sitio. Bajo ninguna circunstancia seremos
            responsables por cualquier pérdida o daño, incluyendo lesiones
            personales o la muerte, como resultado del uso del Sitio, asistencia
            a un evento de la Marca, de cualquier Contenido del Usuario
            publicado en o a través del Sitio, o de la conducta de cualquiera de
            los Usuarios, ya sea en línea o fuera de línea. Adicionalmente, no
            tendremos ninguna responsabilidad por cualesquier virus o cualquier
            cosa fuera de nuestro control. No somos responsables de ningún daño
            a su computadora, software, modem, teléfono u otra propiedad que
            resulte de su uso del Sitio. No seremos responsables frente a usted
            si usted no puede ingresar a información a través del Sitio. Nuestro
            Sitio se proporciona «TAL CUAL» y como esté disponible y
            expresamente renuncia a cualquier garantía de idoneidad para un fin
            en particular o de no infracción. No podemos garantizar y no
            prometemos ninguna resultado específico por el uso del Sitio.
          </p>
          <h6 className="text-xl text-exoticberry">
            Limitación de Responsabilidad
          </h6>
          <p>
            EN NINGÚN CASO SEREMOS RESPONSABLES POR ALGÚN DAÑO, RECLAMACIÓN O
            PÉRDIDA INCURRIDA POR USTED, INCLUYENDO PERO NO LIMITADO A DAÑOS
            COMPENSATORIOS, INCIDENTALES, DIRECTOS, INDIRECTOS, ESPECIALES,
            CONSECUENTES O PUNITIVOS, INDEPENDIENTEMENTE DE SI HEMOS SIDO
            INFORMADOS DE, SABÍAMOS DE, O DEBIMOS HABER SABIDO DE LA
            PROBABILIDAD DE DICHOS DAÑOS. ESTA LIMITACIÓN ES APLICABLE A TODAS
            LAS CAUSAS DE ACCIÓN EN CONJUNTO, INCLUYENDO SIN LIMITACIÓN AL
            INCUMPLIMIENTO DE CONTRATO, INCUMPLIMIENTO DE GARANTÍA, DIFAMACIÓN,
            NEGLIGENCIA, RESPONSABILIDAD ESTRICTA, DECLARACIONES FALSAS, Y OTROS
            AGRAVIOS, ASÍ COMO DEMANDAS DE TERCEROS. SI SE DETERMINA QUE LAS
            GARANTÍAS DE EXCLUSIÓN O LIMITACIONES DE RESPONSABILIDAD
            ESTABLECIDAS EN ESTE CONTRATO DE USO SON INVÁLIDAS O INAPLICABLES
            POR CUALQUIER MOTIVO, USTED ACEPTA QUE NUESTRA RESPONSABILIDAD TOTAL
            NO DEBERÁ EXCEDER CIEN DÓLARES MONEDA EN CURSO LEGAL DE LOS ESTADOS
            UNIDOS DE AMÉRICA (US$100.00).
          </p>
          <h6 className="text-xl text-exoticberry">
            Nuestros Proveedores de Servicio
          </h6>
          <p>
            Podemos utilizar a uno o más proveedores terceros de servicios para
            diseñar y/o operar partes del Sitio. En dichos casos, cada proveedor
            de servicio también se incluye bajo los términos «nosotros» ,
            «nuestro» y «nos» para fines de los Términos. Indemnización Usted
            acepta indemnizar y sacar en paz y a salvo a E. & J. Gallo Winery,
            sus subsidiarias, y afiliadas, y sus respectivos funcionarios,
            agentes, socios y empleados, de cualquier pérdida, responsabilidad,
            costo, gasto, reclamación o demanda, incluyendo sin limitación,
            honorarios razonables de abogados, vencidos o con relación a o que
            surjan por su uso del Sitio en violación de los Términos y/o
            cualquier incumplimiento con las declaraciones y garantías
            establecidas por usted en los Términos y/o que surjan de o
            relacionado con cualquier contenido que usted publique. No afirmamos
            que el Sitio, cualquier Contenido de la Marca, Contenido del Usuario
            u otro Contenido sean apropiados o puedan ser transmitidos, usados o
            instalados fuera de Estados Unidos. El acceso por ciertas personas o
            en ciertos países puede no ser legal. Si usted ingresa al Sitio
            fuera de Estados Unidos, lo hace bajo su propio riesgo y usted es
            responsable por el cumplimiento con las leyes de su jurisdicción.
          </p>
          <h6 className="text-xl text-exoticberry">Misceláneas</h6>
          <p>
            Los Términos serán interpretados, y su cumplimiento ejecutado, bajo
            las leyes de California sin referencia a los principios de elección
            del derecho aplicable. Cualquier controversia relacionada con los
            Términos o el Sitio se podrá litigar únicamente en un tribunal que
            tenga jurisdicción en el Condado de Sacramento para causas de acción
            de tribunal estatal y en el Distrito Este de California para causas
            de acción federal. La Convención de las Naciones Unidas sobre los
            Contratos de Compraventa Internacional de Mercaderías es inaplicable
            a los Términos. Podemos ceder los Términos, parcial o totalmente, a
            una sociedad relacionada o a un tercero o a cualquier tercero con
            relación a la venta, cesión u otra forma de transmisión del Sitio o
            la venta, cesión, fusión, restructura, u otra forma de transmisión
            de cualquier de nuestras marcas o compañías.
          </p>
          <h6 className="text-xl text-exoticberry">Arbitraje</h6>
          <p>General</p>
          <p>
            POR EL SIMPLE USO DE NUESTRO SITIO, USTED ACEPTA EL ARBITRAJE COMO
            MEDIO DE RESOLUCIÓN DE CONTROVERSIAS Y RECLAMACIONES ENTRE NOSOTROS.
            Este acuerdo de arbitraje tiene la intención de ser ampliamente
            interpretado. Incluye, pero no está limitado a: (i) demandas que
            surjan de o relacionadas con cualquier aspecto de la relación entre
            nosotros, ya sea que esté basada en un contrato, agravio, código,
            fraude, declaración en falso, o cualquier otra teoría legal; (ii)
            demandas que surjan previo a estos Términos o a que los Términos
            anteriores fueran adoptados; (iii) demandas que sean actualmente
            materia de un supuesto litigio de demanda colectiva en el cual usted
            no sea miembro de un grupo certificado; y (iv) demandas que puedan
            surgir posterior a la terminación de estos Términos de Uso. Usted
            acepta que al celebrar estos Términos de Uso, usted y nuestra Marca
            renuncian al derecho a un juicio por jurado o a participar en una
            demanda colectiva. Estos Términos de Uso son prueba de una operación
            de comercio interestatal, y por lo tanto la Ley Federal de Arbitraje
            regirá la interpretación y cumplimiento de esta disposición. Esta
            disposición de arbitraje subsistirá a la terminación de estos
            Términos de Uso. Notificación de Controversias Para todas las
            controversias y demandas, ya sea tramitadas en tribunal o por medio
            de arbitraje, se nos deberá otorgar primero la oportunidad de
            resolver su controversia o demanda por medio de una Notificación por
            escrito de Controversia (la «Notificación») a la siguiente
            dirección: Oficina para Resolución de Controversias, E. & J. Gallo
            Winery, 1541 Cummins Drive, Modesto, CA 95358. La Notificación
            deberá (a) describir la naturaleza y fondo de la controversia o
            demanda y (b) establecer el remedio específico que se busca obtener
            (la «Demanda»). Si la Marca y usted no llegan a un acuerdo para
            resolver la controversia o demanda dentro de los 30 días a partir de
            que se recibe la Notificación, usted o nuestra Marca puede iniciar
            un procedimiento de arbitraje. Durante el arbitraje, la cantidad de
            cualquier ofrecimiento de arreglo realizado por nosotros o usted no
            será revelado al arbitro sino hasta después de que el árbitro
            determine la cantidad, en su caso, a la cual usted o nosotros
            tengamos derecho. El arbitraje será regido por las Normas de
            Arbitraje Comercial y sus Procedimientos Suplementarios para la
            resolución de Controversias de Consumidores (conjuntamente las
            «Normas AAA») de la Asociación Americana de Arbitraje («AAA»), según
            sean modificadas por estos Términos de Uso y serán administradas por
            la AAA. Las reglas de la AAA se encuentran disponibles en
            www.adr.org o llamando al número +1-800-778-7879. El árbitro se
            encuentra sujeto por los términos de estos Términos de Uso. El
            árbitro, y no ningún tribunal o agencia federal, estatal o local,
            tendrán la facultad exclusiva para resolver cualquier disputa
            relacionada con la interpretación, aplicabilidad, exigibilidad o
            creación de los Términos, incluyendo pero no limitado a cualquier
            demanda de que todos o parte de los Términos son inválidos o
            anulables. El pago de los honorarios de la AAA por la presentación,
            administración y los árbitros por cualquier arbitraje presentado
            bajo el presente se regirán por las Normas AAA; sin embargo, para
            demandas menores a $75,000 en las cuales usted proporcionó una
            notificación y negoció de buena fe según se estableció anteriormente
            previo a iniciar el arbitraje, si el arbitro encuentra que usted es
            la parte vencedora en el arbitraje, usted tendrá derecho a recuperar
            honorarios y gastos razonables de abogado. Excepto por demandas
            menores a $75,000 que sean determinadas como frívolas, aceptamos no
            buscar una indemnización de honorarios de abogado en dichos
            procedimientos de arbitraje incluso si se encuentra disponible una
            indemnización bajo la ley aplicable. Los términos son aceptados una
            vez que utilice el Sitio o cualquiera de sus funciones y se vuelve a
            confirmar una vez que se convierte en un Miembro (en su caso). Los
            Términos constituyen el entendimiento completo entre usted y nuestra
            Marca con relación al uso del Sitio y sus servicios y funciones. Que
            no ejerzamos o ejecutemos algún derecho o disposición bajo los
            Términos no significará una renuncia a dicho derecho o disposición.
            Los encabezados en los Términos son únicamente como referencia y no
            tendrán ningún efecto contractual o legal. Los Términos operan en la
            medida más extensiva permitida por la ley. Si se determina que
            cualquier disposición de los Términos es ilegal, inválido o
            inaplicable, dicha disposición se entenderá separada de estos
            Términos y no afectará la validez y exigibilidad del resto de las
            disposiciones.
          </p>
        </section>
      </main>
    </Layout>
  );
}

export default TyC;
